export const scenarios = {
  dev: ['SaltedgeReturn', 'GeneralConditionsIkano', 'SignDocument'],
  mock: ['Mocks'],
  init: ['Initial'],
  videoId: [
    'GeneralConditions',
    'UserData',
    'SignDocument',
    'DocumentType',
    'VideoOnboarding',
    'VideoId',
  ],
  'videoId/payment.authorization': [
    'GeneralConditionsBeforeBankSelection',
    'BankSelection',
    'UserData',
    'DocumentType',
    'VideoOnboarding',
    'VideoId',
  ],
  'videoId/signicat.video.micropayment': [
    'GeneralConditionsBeforeBankSelection',
    'BankSelection',
    'UserData',
    'DocumentType',
    'VideoOnboarding',
    'VideoId',
  ],
  'videoId/ikano.de.videoid': [
    'GeneralConditionsIkano',
    'SignDocument',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkanoPageStandalone',
    'Otp',
  ],
  'videoId/ikano.de': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'videoId/ikano.de.fleks': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'videoId/ikano.de.openBanking.2': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'videoId/signicat.qes.micropayment': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoId',
  ],
  'videoId/signicat.eid.idin.videoId': ['UserData', 'DocumentType', 'VideoId'],
  'videoId/ikano.de.goToMobile': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'videoId/ikano.de.goToMobile.mock': [
    'GeneralConditionsWithMicropaymentIkano',
    'SignDocumentIkano',
    'UserData',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'videoId/ikano.de.addressVerification': [
    'MicropaymentInfoWithVideoIdIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'videoId/ikano.addressVerification.test': [
    'MicropaymentInfoWithVideoIdIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'videoId/signicat.addressVerification.test': [
    'MicropaymentInfoWithVideoIdIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'videoId/jitpay.eu': [
    'GeneralConditionsJitpay',
    'UserData',
    'SignDocument',
    'DocumentType',
    'VideoOnboardingJitpay',
    'VideoIdJitpay',
  ],
  'videoId/jitpay.qes': [
    'GeneralConditionsJitpayQES',
    'UserData',
    'SignDocument',
    'DocumentType',
    'VideoOnboardingJitpay',
    'VideoIdJitpay',
  ],
  micropayment: ['MicropaymentInfo', 'MicropaymentFintec'],
  'micropayment-fintec': ['MicropaymentInfo', 'MicropaymentFintec'],
  'micropayment/ikano.de': [
    'MicropaymentInfoWithVideoIdIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.fleks': [
    'MicropaymentInfoWithVideoIdIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.reversed': [
    'MicropaymentReversedInfoWithVideoIdIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/signicat.qes.micropayment': [
    'MicropaymentInfoWithVideoIdIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintec',
  ],
  'micropayment/ikano.de.openBanking1': [
    'MicropaymentInfoIkanoOpenBanking',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.openBanking.1': [
    'MicropaymentInfoIkanoOpenBanking',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.b': [
    'GeneralConditionsIkano',
    'MicropaymentFintecIkano',
    'UserData',
    'SignDocumentIkano',
    'DocumentTypeIkano',
    'VideoIdIkano',
  ],
  'micropayment/ikano.addressVerification.test': [
    'MicropaymentInfoWithVideoIdIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/signicat.addressVerification.test': [
    'MicropaymentInfoWithVideoIdIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.ais': [
    'MicropaymentInfoIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'micropayment/ikano.de.pis': [
    'MicropaymentInfoIkano',
    'AccountHolderMockIkano',
    'MicropaymentFintecIkano',
    'CompleteIkano',
  ],
  'klarna-kosma': ['MicropaymentKlarna'],
  'klarna-kosma/ikano.de': ['KlarnaPrivacyPolicy', 'MicropaymentKlarna'],
  'klarna-kosma/ikano.de.fleks': ['KlarnaPrivacyPolicy', 'MicropaymentKlarna'],
  'klarna-kosma-pisp': ['KlarnaPrivacyPolicy', 'MicropaymentKlarna'],
  'klarna-kosma/jitpay.eu': [
    'MicropaymentInfoJitpay',
    'MicropaymentKlarnaJitpay',
  ],
  'klarna-kosma/jitpay.qes': [
    'MicropaymentInfoJitpay',
    'MicropaymentKlarnaJitpay',
  ],
  'klarna-kosma/jitpay.eu.micropayment': [
    'MicropaymentInfoJitpayStandalone',
    'MicropaymentKlarnaJitpayStandalone',
  ],
  'address-verification': ['AddressUploadIkano', 'CompleteUpload'],
  'address-verification/ikano.de': ['AddressUploadIkano', 'CompleteUpload'],
  'address-verification/ikano.de.fleks': [
    'AddressUploadIkano',
    'CompleteUpload',
  ],
  'utility-bill-address-verification': ['AddressUploadIkano', 'CompleteUpload'],
  'residence-permit-address-verification': [
    'AddressVerificationTwoSidedIkano',
    'AddressUploadBothSidesIkano',
    'CompleteUpload',
  ],
  kontomatik: [
    'IntroKontomatik',
    'KontomatikPage',
    'ProgressPageKontomatik',
    'AddressUploadPageKontomatik',
  ],
  pooling: ['Progress'],
  'pooling-jitpay': ['ProgressPageJitpay'],
  'video-id-retry/ikano.eu': ['VideoIdIkano'],
  'video-id-retry': ['VideoId'],
  'video-id-retry-ikano': ['VideoIdIkano'],
  'video-id-retry-jitpay': ['VideoIdJitpay'],
  'switch-to-mobile': ['SwitchToMobile'],
  'continue-on-mobile': ['ContinueOnMobile'],
  'incorrect-pin-code': ['IncorrectPinCode'],
  'thank-you-mobile': ['GoToMobileThankYou'],
  'device-switched': ['DeviceSwitched'],
  'continue-on-computer': ['ContinueOnComputer'],
  resume: ['ResumeFromEmail'],
  error: ['Error'],
  'klarna-kosma/dtp.openBankingLogin': ['DTPInfo', 'AisKlarna'],
  'dtp.openBankingLogin/waiting': ['DTPWaiting'],
  'dtp.openBankingLogin.waiting/waiting': ['DTPWaiting'],
  waiting: ['DTPWaiting'],
  verifying: ['Verifying'],
  progress: ['Progress'],
  'waiting-progress': ['Progress'],
  'eid-retry': ['BankIdRetry'],
  'terms-and-conditions': ['TermsAndConditions'],
  'terms-and-conditions/ikano.de': ['TermsAndConditionsWithMicropaymentIkano'],
  'terms-and-conditions/ikano.de.openBanking1': [
    'TermsAndConditionsMicropaymentInfoIkanoOpenBanking',
  ],
  'terms-and-conditions/ikano.de.openBanking.1': [
    'TermsAndConditionsMicropaymentInfoIkanoOpenBanking',
  ],
  'terms-and-conditions/ikano.de.openBanking.2': [
    'TermsAndConditionsWithMicropaymentIkano',
  ],
  'terms-and-conditions/ikano.de.fleks': [
    'TermsAndConditionsWithMicropaymentIkano',
  ],
  'terms-and-conditions/ikano.de.reversed': [
    'TermsAndConditionsWithMicropaymentIkano',
  ],
  'terms-and-conditions/jitpay.eu': ['TermsAndConditionsJitpay'],
  'terms-and-conditions/dtp.openBankingLogin': ['TermsAndConditionsDTPInfo'],
  'videoId/demo.msteams.videoId': [
    'GeneralConditions',
    'UserData',
    'DocumentType',
    'VideoOnboarding',
    'VideoId',
  ],
  'videoId/demo.google.videoId': [
    'GeneralConditions',
    'UserData',
    'DocumentType',
    'VideoOnboarding',
    'VideoId',
  ],
  'videoId/santander.de.unattended': [
    'DocumentSignIntro',
    'SignDocument',
    'VideoIdIntro',
    'DocumentTypeSantander',
    'VideoOnboarding',
    'VideoId',
  ],
  'terms-and-conditions/santander.de.unattended': [
    'TermsAndConditionsSantander',
  ],
  'salt-edge': ['SaltedgeRedirect'],
  'salt-edge-return': ['SaltedgeReturn'],
  gocardless: ['Country', 'GocardlessBankSelection'],
  'error-ios-browser-not-supported': ['ErrorIosBrowserNotSupported'],
}
