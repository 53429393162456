import { Button, ContentContainer, Header, Icon } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import {
  CenteredContent,
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '@/styles/layout.styles'
import { ParagraphCentered } from '@/styles/generic.styles'

export const DocumentSignIntroPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { pageWidth } = store.AppState
  const { currentPageIndex } = store.ScenarioState

  const [mobileVersion, setMobileVersion] = useState(false)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <Margined margin="0 0 15px">
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          Sign a contract
        </Header>
      </Margined>

      <CenteredContent>
        <Icon type="audit-log" size="140px" />
      </CenteredContent>

      <ParagraphCentered>
        In the next step, you will be asked to review the contract and sign it.
      </ParagraphCentered>

      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() =>
            store.ScenarioState.setCurrentPage(currentPageIndex + 1)
          }
          {...theme.button}
          minWidth="224px"
          paddingMobile="14px 40px"
        >
          Continue
        </Button>
        <Margined margin="0 0 30px" />
      </ContentContainerBottomFixed>
      <MobileSpacerForContentContainerBottomFixed />
    </ContentContainer>
  )
})
