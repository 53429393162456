export const pinkTheme = {
  globals: {
    name: 'Pink',
    fontFamily: 'Inter',
    fontFamilyHeadline: 'Inter',
    fontFamilyBold: 'Inter',
    bgColor: 'rgba(248, 247, 246, 1)',
    linkColor: 'rgba(253, 80, 135, 1)',
    linkColorHover: 'rgba(253, 80, 135, 0.8)',
    checkboxColor: 'rgba(0, 0, 0, 1)',
    checkboxColorActive: 'rgba(253, 80, 135, 1)',
    checkboxBorderRadius: '4px',
    inputRequired: {
      asteriskColor: '#FD5087',
      textColor: '#FD5087',
      textFontSize: '14px',
    },
  },
  contentWrap: {},
  container: {
    bgColor: '#FFFFFF',
    width: '100%',
    padding: '50px',
    borderRadius: '8px',
    border: '1px solid rgba(230, 221, 249, 1)',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.07)',
    margin: '0 auto',
    color: '#2B253A',
  },
  documentCheckbox: {
    iconColor: 'rgba(253, 80, 135, 1)',
    iconHover: 'rgba(253, 80, 135, 0.8)',
    width: '100%',
    contentFontSize: '14px',
    margin: '20px',
  },
  icon: {
    color: '#FD5087',
    color2: '#FD5087',
    colorHover: 'rgba(253, 80, 135, 0.8) ',
    size: '32px',
    thickness: '0.2px',
  },
  otpInput: {
    width: '100%',
    columnGap: '10px',
    marginTop: '10px',
    marginBottom: '30px',
    inputHeight: '60px',
    inputBorder: '2px solid rgba(183, 189, 198, 1)',
    borderRadius: '4px',
    fontSize: '22px ',
    focusColor: 'rgba(253, 80, 135, 1)',
    bgColor: 'rgba(255, 255, 255, 1)',
    focusBgColor: 'rgba(255, 255, 255, 1)',
    fontColor: '#25292C',
    loadingColor: 'rgba(253, 80, 135, 1)',
  },
  pdfViewer: {
    pageBackground: 'rgba(255, 255, 255, 1)',
    canvasWidth: '495',
    containerPadding: '',
    containerBorder: '2px solid rgba(235, 235, 235, 1)',
    navigationTop: '',
    navigationLeft: '',
    navigationRight: '',
    navigationBottom: '',
    navigationBgColor: 'rgba(206, 212, 220, 0.5)',
    navigationBorderRadius: '2px',
    navigationPadding: '9px 13px',
    navigationBoxShadow: 'none',
    navigationButtonBgColor: 'rgba(253, 80, 135, 1)',
    navigationButtonBorder: '1px solid rgba(253, 80, 135, 1)',
    navigationButtonColor: 'rgba(255, 255, 255, 1)',
    navigationButtonBgColorHover: 'rgba(253, 80, 135, 0.8)',
    navigationButtonBorderHover: '1px solid rgba(253, 80, 135, 0.8)',
    navigationButtonColorHover: 'rgba(255, 255, 255, 1)',
    navigationButtonPadding: '0px 5px',
    navigationButtonColorDisabled: 'rgba(0, 0, 0, 1)',
    navigationButtonBgColorDisabled: 'rgba(235, 235, 235, 1)',
    navigationButtonBorderDisabled: '1px solid rgba(235, 235, 235, 1)',
    navigationSpacerColor: 'rgba(180, 180, 180, 1)',
    searchTop: '',
    searchLeft: '',
    searchRight: '',
    searchBottom: '',
    searchBgColor: 'rgba(206, 212, 220, 0.5)',
    searchBorderRadius: '2px',
    searchPadding: '9px 13px',
    searchBoxShadow: 'none',
    searchColor: 'rgba(0, 0, 0, 1)',
    searchFontSize: '',
    searchInputRadius: '2px',
    searchInputHeight: '',
    searchInputColor: 'rgba(0, 0, 0, 1)',
    searchInputBgColor: 'rgba(255, 255, 255, 1)',
    searchInputBgColorFocus: 'rgba(255, 255, 255, 1)',
    searchInputBorder: '2px solid rgba(235, 235, 235, 1)',
    searchInputFocusBorder: '2px solid rgba(0, 0, 0, 1)',
    searchInputFocusOutline: 'none',
    searchInputPadding: '',
    scrollTrackColor: '#CED4DC',
    scrollHandleColor: 'rgba(253, 80, 135, 1)',
    scrollRadius: '0px',
  },
  button: {
    bgColor: 'rgba(253, 80, 135, 1)',
    padding: '10px 34px',
    borderRadius: '6px',
    width: 'auto',
    border: '1px solid rgba(253, 80, 135, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(255, 255, 255, 1)',
    colorHover: 'rgba(255, 255, 255, 1)',
    bgColorHover: 'rgba(253, 80, 135, 0.8)',
    borderHover: '1px solid rgba(253, 80, 135, 0.8)',
    bgColorDisabled: 'rgba(235, 235, 235, 1)',
    borderDisabled: '1px solid rgba(235, 235, 235, 1)',
    colorDisabled: 'rgba(0, 0, 0, 1)',
    fontSize: '14px',
    fontWeight: '700',
  },
  buttonDisabled: {
    padding: '10px 34px',
    borderRadius: '6px',
    width: 'auto',
    boxShadow: 'none',
    margin: '0',
    fontSize: '14px',
    fontWeight: '700',
    bgColor: 'rgba(235, 235, 235, 1)',
    border: '1px solid rgba(235, 235, 235, 1)',
    color: 'rgba(0, 0, 0, 1)',
    bgColorHover: 'rgba(235, 235, 235, 1)',
    borderHover: '1px solid rgba(235, 235, 235, 1)',
    colorHover: 'rgba(0, 0, 0, 1)',
    cursor: 'not-allowed',
  },
  header: {
    color: '#34353A',
    fontSize: '24px',
    lineHeight: '36px',
    fontSizeMobile: '20px',
    lineHeightMobile: '32px',
    margin: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  selectInput: {
    borderRadius: '4px',
    borderWidth: '1px',
    borderColorFocus: 'rgba(235, 235, 235, 1)',
    backgroundColorSelected: 'rgba(235, 235, 235, 1)',
    backgroundColorFocus: 'rgba(206, 212, 220, 0.75)',
    currentBg: 'rgba(206, 212, 220, 0.50)',
    indicatorColorFocus: 'rgb(102, 102, 102)',
    indicatorColorHover: 'rgb(153, 153, 153)',
    danger: 'rgba(217, 17, 17, 1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  symbol: {
    primary: 'rgba(253, 80, 135, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
    tenary: 'rgba(248, 247, 246, 1)',
  },
  textInput: {
    bgColor: 'white',
    padding: '14px',
    borderRadius: '4px',
    border: '2px solid rgba(183, 189, 198, 1)',
    boxShadow: 'none',
    margin: '0',
    color: '#2B253A',
    colorFocus: 'rgba(0, 0, 0, 1)',
    bgColorFocus: 'white',
    borderFocus: '2px solid rgba(0, 0, 0, 1)',
    bgColorDisabled: 'rgba(235, 235, 235, 1)',
    borderDisabled: 'rgba(235, 235, 235, 1)',
    colorDisabled: 'rgba(235, 235, 235, 1)',
    fontSize: '14px',
    fontWeight: '300',
    outline: 'none',
    errorColor: 'rgba(217, 17, 17, 1)',
  },
  brandBar: {
    bgColor: 'white ',
    padding: '30px',
    margin: '0 0 30px 0',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'normal',
  },
  wrapXs2a: {
    fontFamily: 'Inter',
    fontFamilyHeadline: 'Inter',
    bgColor: 'rgba(248, 247, 246, 1)',
    linkColor: 'rgba(253, 80, 135, 1)',
    linkColorHover: 'rgba(253, 80, 135, 0.8)',
    checkboxColor: 'rgba(0, 0, 0, 1)',
    checkboxColorActive: 'rgba(253, 80, 135, 1)',
    backButton: {
      borderColor: 'rgba(253, 80, 135, 1)',
      color: '#2B253A',
      colorHover: 'rgba(0, 0, 0, 1)',
      disabled: 'rgba(235, 235, 235, 1)',
    },
    input: {
      bgColor: 'white',
      padding: '14px',
      borderRadius: '4px',
      border: '2px solid rgba(183, 189, 198, 1)',
      boxShadow: 'none',
      margin: '0',
      color: '#2B253A',
      colorFocus: 'rgba(0, 0, 0, 1)',
      bgColorFocus: 'white',
      borderFocus: '2px solid rgba(0, 0, 0, 1)',
      bgColorDisabled: 'rgba(235, 235, 235, 1)',
      borderDisabled: 'rgba(235, 235, 235, 1)',
      colorDisabled: 'rgba(235, 235, 235, 1)',
      fontSize: '14px',
      fontWeight: '300',
      outline: 'none',
      errorColor: 'rgba(217, 17, 17, 1)',
    },
    button: {
      bgColor: 'rgba(253, 80, 135,1)',
      padding: '10px 34px',
      paddingMobile: '14px 40px',
      borderRadius: '6px',
      width: 'auto',
      border: '1px solid rgba(253, 80, 135,1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(255, 255, 255, 1)',
      colorHover: 'rgba(255, 255, 255, 1)',
      bgColorHover: 'rgba(253, 80, 135, 0.8)',
      borderHover: '1px solid rgba(253, 80, 135, 0.8)',
      bgColorDisabled: 'rgba(235, 235, 235, 1)',
      borderDisabled: '1px solid rgba(235, 235, 235, 1)',
      colorDisabled: 'rgba(0, 0, 0, 1)',
      fontSize: '14px',
      fontWeight: '700',
    },
  },
  wrapKlarna: {
    bgColor: 'rgba(235, 235, 235, 1)',
  },
  wrapKontomatik: {
    bgColor: '#FFF',
    textColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(230, 221, 249, 1)',
    headerColor: 'rgba(0, 0, 0, 1)',
    primaryColor: 'rgba(253, 80, 135, 1)',
    primaryReverseColor: '#FFF',
    secondaryColor: '586374',
    mainHoverColor: 'rgba(253, 80, 135, 1)',
    mainHoverReverseColor: '#FFF',
    listHoverColor: '#F5F5F5',
    disabledColor: '#CED4DA',
    errorColor: 'rgba(217, 17, 17, 1)',
    borderRadius: '8px',
    width: '100%',
    padding: '50px',
    boxShadow: 'none',
    margin: '0 auto',
  },
  progressBar: {
    time: 180,
    secondChance: true,
    showCounter: true,
    height: '17px',
    margin: '40px 0 10px 0',
    padding: '0 20px',
    border: '2px solid rgba(183, 189, 198, 1)',
    borderRadius: '0px',
    background: 'white',
    barFill: 'rgba(253, 80, 135, 1)',
    counterMargin: '20px 0',
    counterPadding: '0',
    counterFontSize: '24px',
    counterColor: 'rgba(0, 0, 0, 1)',
    infoMessageTextAlign: 'center',
    infoMessageFontSize: '12px',
    infoMessageMargin: '6px 0 0 0',
  },
  stepper: {
    borderColor: 'rgba(235, 235, 235, 1)',
    fontSizeTitle: '12px',
    fontSizeDesc: '10px',
  },
  loadingSpinner: {
    primary: 'rgba(253, 80, 135, 1)',
    bgColor: 'rgba(255, 255, 255, 1)',
  },
  tabSwitcher: {
    borderBottom: '2px solid #dbdbdb',
    buttonColor: '#9b9b9b',
    buttonBorderBottom: '4px solid rgba(183, 50, 115,1)',
    buttonColorActive: '#000',
  },
  errorPage: {
    title: {
      color: '#34353A',
    },
    subtitle: {
      color: '#2B253A',
    },
    svg: {
      fill1: 'none',
      fill2: '#ffffff',
      fill3: '#000',
      fill4: 'rgba(253, 80, 135, 1)',
    },
  },
  popup: {
    clickOutsideEnabled: true,
  },
  videoIdCallToActionButton: {
    bgColor: 'rgba(253, 80, 135, 1)',
    padding: '10px 34px',
    borderRadius: '100px',
    width: '150px',
    border: '1px solid rgba(253, 80, 135, 1)',
    boxShadow: 'none',
    margin: '32px 0 0 0',
    color: 'rgba(255, 255, 255, 1)',
    colorHover: 'rgba(255, 255, 255, 1)',
    bgColorHover: 'rgba(253, 80, 135, 0.8)',
    borderHover: '1px solid rgba(253, 80, 135, 0.8)',
    bgColorDisabled: 'rgba(235, 235, 235, 1)',
    borderDisabled: '1px solid rgba(235, 235, 235, 1)',
    colorDisabled: 'rgba(0, 0, 0, 1)',
    fontSize: '14px',
    fontWeight: '700',
    textDecoration: 'none',
  },
  videoIdPhoneChangeButton: {
    color: 'rgba(253, 80, 135, 1)',
  },
}
