import React from 'react'

interface ImageTypeProps {
  color: string
}

export const Pictogram = ({ color }: ImageTypeProps) => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="140" height="140" rx="70" fill="#F7F9FC" />
      <path
        d="M45.2498 91.6669C45.2498 90.8845 45.884 90.2502 46.6664 90.2502H55.5206C56.303 90.2502 56.9373 90.8845 56.9373 91.6669C56.9373 92.4493 56.303 93.0835 55.5206 93.0835H46.6664C45.884 93.0835 45.2498 92.4493 45.2498 91.6669Z"
        fill={color || '#2A0062'}
      />
      <path
        d="M62.6039 90.2502C61.8215 90.2502 61.1873 90.8845 61.1873 91.6669C61.1873 92.4493 61.8215 93.0835 62.6039 93.0835H71.4581C72.2405 93.0835 72.8748 92.4493 72.8748 91.6669C72.8748 90.8845 72.2405 90.2502 71.4581 90.2502H62.6039Z"
        fill={color || '#2A0062'}
      />
      <path
        d="M77.1248 91.6669C77.1248 90.8845 77.759 90.2502 78.5414 90.2502H87.3956C88.178 90.2502 88.8123 90.8845 88.8123 91.6669C88.8123 92.4493 88.178 93.0835 87.3956 93.0835H78.5414C77.759 93.0835 77.1248 92.4493 77.1248 91.6669Z"
        fill={color || '#2A0062'}
      />
      <path
        d="M94.4789 90.2502C93.6965 90.2502 93.0623 90.8845 93.0623 91.6669C93.0623 92.4493 93.6965 93.0835 94.4789 93.0835H103.333C104.115 93.0835 104.75 92.4493 104.75 91.6669C104.75 90.8845 104.115 90.2502 103.333 90.2502H94.4789Z"
        fill={color || '#2A0062'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.9999 56.6668C48.159 56.6668 46.6666 58.1592 46.6666 60.0002V73.3335C46.6666 75.1744 48.159 76.6668 49.9999 76.6668H63.3333C65.1742 76.6668 66.6666 75.1744 66.6666 73.3335V60.0002C66.6666 58.1592 65.1742 56.6668 63.3333 56.6668H49.9999ZM63.3333 59.5002H49.9999C49.7238 59.5002 49.4999 59.724 49.4999 60.0002V73.3335C49.4999 73.6096 49.7238 73.8335 49.9999 73.8335H63.3333C63.6094 73.8335 63.8332 73.6096 63.8332 73.3335V60.0002C63.8332 59.724 63.6094 59.5002 63.3333 59.5002Z"
        fill={color || '#2A0062'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.6666 63.3335C96.6666 67.0154 93.6818 70.0002 89.9999 70.0002C86.318 70.0002 83.3333 67.0154 83.3333 63.3335C83.3333 59.6516 86.318 56.6668 89.9999 56.6668C93.6818 56.6668 96.6666 59.6516 96.6666 63.3335ZM93.8333 63.3335C93.8333 65.4506 92.117 67.1668 89.9999 67.1668C87.8828 67.1668 86.1666 65.4506 86.1666 63.3335C86.1666 61.2164 87.8828 59.5002 89.9999 59.5002C92.117 59.5002 93.8333 61.2164 93.8333 63.3335Z"
        fill={color || '#2A0062'}
      />
      <path
        d="M89.9998 74.7502C84.8372 74.7502 80.564 78.5682 79.8537 83.5341C79.7429 84.3087 79.0252 84.8467 78.2507 84.7359C77.4762 84.6251 76.9381 83.9075 77.0489 83.1329C77.956 76.7913 83.4074 71.9169 89.9998 71.9169C96.5922 71.9169 102.044 76.7913 102.951 83.1329C103.061 83.9075 102.523 84.6251 101.749 84.7359C100.974 84.8467 100.257 84.3087 100.146 83.5341C99.4356 78.5682 95.1623 74.7502 89.9998 74.7502Z"
        fill={color || '#2A0062'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3333 44.7502C28.3333 41.2063 31.2061 38.3335 34.7499 38.3335H98.0832C101.627 38.3335 104.5 41.2063 104.5 44.7502V46.6668H106.417C109.96 46.6668 112.833 49.5397 112.833 53.0835V96.4168C112.833 99.9607 109.96 102.833 106.417 102.833H43.0833C39.5394 102.833 36.6666 99.9607 36.6666 96.4168V94.5002H34.7499C31.2061 94.5002 28.3333 91.6273 28.3333 88.0835V44.7502ZM36.6666 91.6668V53.0835C36.6666 49.5397 39.5394 46.6668 43.0833 46.6668H101.667V44.7502C101.667 42.7711 100.062 41.1668 98.0832 41.1668H34.7499C32.7709 41.1668 31.1666 42.7711 31.1666 44.7502V88.0835C31.1666 90.0625 32.7709 91.6668 34.7499 91.6668H36.6666ZM43.0833 49.5002C41.1042 49.5002 39.4999 51.1045 39.4999 53.0835V96.4168C39.4999 98.3959 41.1042 100 43.0833 100H106.417C108.396 100 110 98.3959 110 96.4168V53.0835C110 51.1045 108.396 49.5002 106.417 49.5002H43.0833Z"
        fill={color || '#2A0062'}
      />
    </svg>
  )
}
