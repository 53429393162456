import {
  Button,
  ContentContainer,
  Header,
  Icon,
  Symbol,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  OnboardingIcon,
  OnboardingItem,
  OnboardingList,
  OnboardingSingleLine,
} from '../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '../styles/layout.styles'

export const VideoOnboardingPage = observer(() => {
  const store = useContext(StoreContext)
  const { currentPageIndex, context } = store.ScenarioState
  const { theme, themeId } = store.InterfaceState
  const { docType, pageWidth } = store.AppState

  const { videoOnboardingPage: trans } = store.TranslationsState.translations

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (!context?.showVideoIdHintsScreen) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
  }, [])

  const [mobileVersion, setMobileVersion] = useState(false)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <OnboardingList>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol
              type="utility-bill"
              {...theme.symbol}
              size="58px"
              {...(themeId === 'santanderTheme' && { primary: '#5f36bc' })}
            />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.wellLitPlace}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol
              type="identity-card"
              {...theme.symbol}
              size="58px"
              {...(themeId === 'santanderTheme' && { primary: '#5f36bc' })}
            />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.originalDocument}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem>
          <OnboardingIcon>
            <Symbol
              type="globe"
              {...theme.symbol}
              size="58px"
              {...(themeId === 'santanderTheme' && { primary: '#5f36bc' })}
            />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.wifi} </OnboardingSingleLine>
        </OnboardingItem>
      </OnboardingList>

      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() => handleProceed()}
          disabled={
            context?.shouldSelectDocument ? !(docType && docType.length) : false
          }
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
        >
          <>
            {themeId !== 'santanderTheme' && (
              <ButtonImgWrap>
                <Icon size="18px" type="checkmark" />
              </ButtonImgWrap>
            )}
            <ButtonText>{trans.start}</ButtonText>
          </>
        </Button>
        <Margined margin="0 0 30px" />
      </ContentContainerBottomFixed>
      <MobileSpacerForContentContainerBottomFixed />
    </ContentContainer>
  )
})
